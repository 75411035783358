<template>
  <section>
    <b-col cols="12">
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-card>
            <b-row>
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                class="d-flex justify-content-center"
              >
                <b-img
                  class="rounded-lg"
                  src="@/assets/images/PaymentMethods/mercadopago.jpg"
                  width="200px"
                />
              </b-col>
              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
                :class="screen.width < 980 ? 'mb-2' : 'border-right'"
              >
                <div :class="screen.width < 980 ? 'text-center mt-2' : ''">
                  <span
                    style="font-size: 18px"
                    class="font-weight-bolder"
                  >
                    Adição de Saldo
                  </span>
                  <br>
                  <small class="text-muted"> Por Mercado Pago (Pix) </small>
                </div>

                <b-input-group
                  prepend="R$"
                  class="mt-1"
                >
                  <b-form-input
                    v-model="price_selected"
                    type="number"
                    placeholder="100"
                  />
                </b-input-group>
              </b-col>

              <b-col
                cols="12"
                lg="4"
                md="12"
                sm="12"
              >
                <h3
                  style="font-size: 16px"
                  class="font-weight-bolder text-center"
                >
                  Saldo que será adicionado
                </h3>

                <h3
                  style="font-size: 20px"
                  class="font-weight-bolder text-success text-center mt-2"
                >
                  {{ price }}
                </h3>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <label class="section-label mb-1">Detalhes da Compra</label>
            <hr>

            <!-- Detalhes da Compra -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <span class="font-weight-bolder"> Detalhes do Preço </span>
              </b-col>

              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span> Valor </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ price }}</span>
              </b-col>

              <b-col cols="6">
                <span>
                  <span> Encargos </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span
                  class="mr-1 font-weight-bolder text-success"
                >+ R$ 0,00</span>
              </b-col>
            </b-row>

            <hr>

            <b-row>
              <b-col
                cols="6"
                style="margin-bottom: 10px"
              >
                <span>
                  <span class="font-weight-bolder"> Total </span>
                </span>
              </b-col>

              <b-col
                cols="6"
                class="d-flex justify-content-end"
              >
                <span class="mr-1">{{ total }}</span>
              </b-col>
            </b-row>

            <div>
              <b-button
                :disabled="loading || !min"
                variant="relief-primary"
                block
                @click="open()"
              >
                <span v-if="!loading"> Finalizar </span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>
              <div class="cho-container" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BImg,
  BInputGroup,
  BFormInput,
  BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
    BInputGroup,
    BFormInput,
    BSpinner
  },
  data: () => ({
    loading: false,
    price_selected: null,
    mp_checkout: null
  }),
  computed: {
    screen () {
      return {
        width: window.screen.width,
        height: window.screen.height
      }
    },
    price () {
      const value = this.price_selected
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(value)
    },
    encargos () {
      const value = this.price_selected
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format((value / 100) * 5)
    },
    total () {
      const value = this.price_selected
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })

      return formatter.format(value)
    },
    min () {
      return this.price_selected >= 0.5
    }
  },
  mounted () {
    this.price_selected = parseFloat(
      this.$router.currentRoute.params.value
    ).toFixed(2)
  },
  methods: {
    checkIfIsAppleDevices () {
      const isApple = navigator.userAgent.match(
        /(iPad|iPhone|iPod)/g
      )

      return isApple
    },
    open () {
      this.loading = true
      this.$http
        .get(`user/adicionar_saldo/mercado_pago/pix/${this.price_selected}`)
        .then(res => {
          if (this.checkIfIsAppleDevices()) {
            return window.location.assign(res.data.response.init_point)
          }

          window.open(res.data.response.init_point)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
